/*
Template Name: Citizen to Government Platform
Author: Skyniche
Version: 1.0.0
Website: https://skyniche.com/
Contact: info@skyniche.com
File: Main Css File
*/

/* =======================
    TABLE OF CSS
    1. Fonts 
    2. General 
    3. Left Menu 
    4. Topbar 
    5. Page Head 
    6. Footer 
    7. Horizontal Nav 
    8. Right Sidebar 
    9. Layouts 
    10. Helper 
    11. Social 
    12. Widgets 
    13. Custom Checkbox 
    14. Custom Radio 
    15. Print 
    16. Preloader 
    17. Plugins 
    18. Components Demo 
    19. Tasks 
    20. Email 
    21. Timeline 
    22. Extra Pages 
    23. Authentication 
   ======================= */



//Fonts
/* 1. Fonts */
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
/* 2. General */
@import "custom/structure/general";
/* 3. Left Menu */
@import "custom/structure/left-menu";
/* 4. Topbar */
@import "custom/structure/topbar";
/* 5. Page Head */
@import "custom/structure/page-head";
/* 6. Footer */
@import "custom/structure/footer";
/* 7. Horizontal Nav */
@import "custom/structure/horizontal-nav";
/* 8. Right Sidebar */
@import "custom/structure/right-sidebar";
/* 9. Layouts */
@import "custom/structure/layouts";

//Components
/* 10. Helper */
@import "custom/components/helper";
/* 11. Social */
@import "custom/components/social";
/* 12. Widgets */
@import "custom/components/widgets";
/* 13. Custom Checkbox */
@import "custom/components/custom-checkbox";
/* 14. Custom Radio */
@import "custom/components/custom-radio";
/* 15. Print */
@import "custom/components/print";
/* 16. Preloader */
@import "custom/components/preloader";

// Plugins
/* 17. Plugins */
@import "custom/plugins/slimscroll";
@import "custom/plugins/apexcharts";
@import "custom/plugins/calendar";
@import "custom/plugins/react-select";
@import "custom/plugins/multiple-select";
@import "custom/plugins/flatpickr";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/parsley";
@import "custom/plugins/form-wizard";
@import "custom/plugins/editor";
@import "custom/plugins/dropzone";
@import "custom/plugins/datatable";
@import "custom/plugins/table-list-js";
@import "custom/plugins/kanban";
@import "custom/plugins/metismenu";

//Pages
/* 18. Components Demo */
@import "custom/pages/components-demo";
/* 19. Tasks */
@import "custom/pages/tasks";
/* 20. Email */
@import "custom/pages/email";
/* 21. Timeline */
@import "custom/pages/timeline";
/* 22. Extra Pages */
@import "custom/pages/extra-pages";
/* 23. Authentication */
@import "custom/pages/authentication";
 
.green-color
{
background-color:#006239;
border-color: #006239;
}
.white
{
color:#fff!important;
}
a.text-primary:hover
{
color:#fff!important;
}
h1,h2,h3,h4,h5,h6,p
{
   color:#000;
}
.table
{
  color:#000;
}
body
{
  color:#000!important;
}
.link-color
{
  color:#178029!important;
  font-size:16px!important;
}
.blk-color
{
  color:#000!important;
  font-size:16px!important;
}
.btn-btn:hover
{
background-color:#fff;
color:#000;
border:1px solid #006239;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.custom-control-input:checked ~ .custom-control-label::before
{
border-color:#006239!important;
background-color:#006239!important;
}


.invalid-feedback{
	font-size: 100%;
}

.btn-primary:not(:disabled):not(.disabled):active
{
    background-color: #006239;
    border-color: #006239;
}
.black
{
color:#000!important;
}
.img-size
{
    height: 8rem!important;
    width: 8rem!important;
}
.ic-position{
	bottom: -7px;
    right: 3px;
    position: absolute;
}
.icon-white
{
  color:#fff!important;
  width:8%;
}

.profile-tabs{
	.nav-pills{
		background-color:transparent;
		.nav-link.active, .show > .nav-link{
			color: #ffffff;
			background-color: #006239;
		}
	}
	.nav-justified .nav-item{
		flex-basis: initial;
		width:100%;
		text-align:left;
	}
	
}

.green-active-tab{
	.nav-pills{
		.nav-link.active, .show > .nav-link{
			color: #ffffff;
			background-color: #006239;
		}
	}
}
    .dashboard-col .col-xl-2
		{
			max-width:19.66667%!important;
			flex: 0 0 19.66667%;
		}
    .dashboard-height.card{
		margin-bottom:0;
		height:100%!important;
		.card-body{
			height:100%;
		}
	}
	
.font-family-bold{
     font-weight:800!important;
}

.font-family-bold-normal{
     font-weight:400!important;
}

.height-chart{
	height:100%;
	.card-body{
		height:100%;
	}
}

.fnt-12{
  font-size:12px;
}
.card-dark-green{
   background:#2b7460;
}
.card-light-green{
   background:#c1d3c6;
   border-radius:15px;
}
.card-border{
   border-radius:15px;
}
.blue{
  background:#5369f8;
}

.theme-bg{
	background-color:$primary;
	h2{
		color:#fff;
	}
	span.text-muted{
		color:#fff !important;
	}
	.btn-white{
		background-color:#fff;
	}
}
.text-center{
	text-align:center !important;
}

html {
  scroll-behavior: smooth;
}

.inline-double-label-hide{
	.form-check-label{
		display:none;
	}
}
.profile-image-border{
	img{
		border: 7px solid #e8e8e8;
	}
	.upload-progress-bar{
		float: left;
		width: 0;
		height: 100%;
		font-size: 12px;
		line-height: 20px;
		color: #fff;
		text-align: center;
		background-color: #5cb85c;
		webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
		box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
		webkit-transition: width .6s ease;
		o-transition: width .6s ease;
		transition: width .6s ease;
	}
	.upload-progress-bar-wrapper{
		height: 10px;
		margin-top: 10px;
		width: 300px;
		float:left;
		overflow: hidden;
		background-color: #f5f5f5;
		border-radius: 50px;
		webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
		box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
	}
	.upload-progress-cancel{
		margin-top: 5px;
		webkit-appearance: none;
		padding: 0;
		cursor: pointer;
		background: 0 0;
		border: 0;
		float: left;
		font-size: 21px;
		font-weight: 700;
		line-height: 1;
		color: #000;
		text-shadow: 0 1px 0 #fff;
		filter: alpha(opacity=20);
		opacity: .2;
	}
}

.border-none{
	border:0 !important;
}

.otp-wrapper{
	div{
		justify-content:center;
		input{
			width: 40px !important;
			height: 50px;
			font-size: 1.6rem;
			margin-left: 5px;
			margin-right: 5px;
			&.error-otp{
				border-color: #f00;
				border-radius: 5px;
			}
		}
	}
}

.no-bottom-form-group{
	.form-group{
		margin-bottom:0px;
	}
}
.invalid-select-feedback{
	color:#ff5c75;
}
.invalid-select-wrapper-feedback{
	.react-select__control{
		border: 1px solid #ff5c75;
	}
}

.invalid-custom-input-wrapper{
	.form-control{
		border: 1px solid #ff5c75;
	}
}

.custom-select-sm{
	min-width:60px;
}

.no-shadow{
	box-shadow:none !important;
}

.re-routes-counts{
	color:#fff;
	background:#f00;
	width:20px;
	height:20px;
	line-height:20px;
	border-radius:50%;
	display:inline-block !important;
	text-align:center;
	font-size:0.76rem;
	padding-left: 0 !important;
	vertical-align: initial !important;
	font-weight:500;
}

.menu-item-counts{
	color:#fff;
	background:#f00;
	min-width:20px;
	height:20px;
	line-height:20px;
	border-radius:500px;
	display:inline-block !important;
	text-align:center;
	font-size:0.76rem;
	padding-left: 5px !important;
	padding-right: 5px !important;
	vertical-align: initial !important;
	font-weight:500;
	&.d-none{
		display: none !important;
	}
}

.chat-item-counts{
	color:#fff;
	background:#f00;
	min-width:20px;
	height:20px;
	line-height:20px;
	border-radius:500px;
	display:inline-block !important;
	text-align:center;
	font-size:0.76rem;
	padding-left: 5px;
	padding-right: 5px;
	vertical-align: initial !important;
	font-weight:500;
}

.custom-file .custom-file-label{
	word-break: break-all;
	height:auto;
	&::after{
		height:auto;
	}
}

.big-count-text{
	font-size: 3rem;
    line-height: 1;
}

.bg-white{
	background:#fff;
}

.w-80{
	width:80%;
}

.poll-question-right{
	width:75%;
}

.nigeria-lag-green{
	background:#008751;
	height:80px;
	width:100%;
}

.border-curved-15{
	border-radius:15px;
}

.navigation-word{
	color:#000;
	margin-left:20px;
}

#sidebar-menu > ul > li > a {
    color: #000;
	padding: 12px 21px;
}

.user-profile .pro-user-name {
    color: #000;
}
/*.card-form .form-control
{
  width:50%;
  margin-left:auto;
  margin-right:auto;
}*/
.green
{
  color:#04543e;
}


.white-bg{
background:#ffffff !important;
}

.chart-filter-options{
	display: flex;
	align-items:center;
	justify-content:flex-end;
	.sort-by{
		margin-right:5px;		
	}
	.react-select{
		min-width:150px;
	}
	&.absolute-filter{
		position: absolute;
		right: 20px;
		z-index: 1000;
	}
}

.chart-polling-filter-wrapper{
	display: flex;
    justify-content: space-between;
	flex-wrap:wrap;
	.sort-by{
		margin-right:5px;		
	}
		
	.chart-polling-filter-options{
		display: flex;
		align-items:center;
		flex-wrap:wrap;
		.react-select{
			min-width:430px;
		}
	}
}

.black-axis-font{
	.apexcharts-yaxis text, .apexcharts-xaxis text{
		fill: #000000;
		font-size:13px;
		font-weight:700;
	}
	.apexcharts-legend-text{
		font-size:13px !important;
		font-weight:700 !important;
		color:#000 !important;
		margin-right: 5px;
	}
	.apexcharts-legend-marker{
		height: 16px !important;
		width: 16px !important;
		margin-right: 6px;
	}
	
	.apexcharts-legend.apexcharts-align-left .apexcharts-legend-series{
		display: flex;
	}
	
	.apexcharts-text tspan{
		text-transform: uppercase;
	}
	&.small-font-xaxis{
		.apexcharts-yaxis text{
			font-size:12px;
		}
		.apexcharts-xaxis text{
			font-size:10px;
		}
	}
}

.small-head-poll{
	font-size: 16px;
}

.extra-space-legend{
	.apexcharts-legend-series{
		margin: 7px 6px !important;
	}
	.apexcharts-legend-text{
		font-size: 14px !important;
	}
}

.receive-call-form{
	.font-weight-bolder{
		font-weight: 800 !important;
		
	}
	label.font-weight-bolder{
		padding-top:10px;
	}
}

.top-nav-mda-name{
	margin-left: 100px;
    color: #006239;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
}

.full-height-chat{
	height:auto;
	&.conversation-list .ctext-wrap{
		background-color: #ffffff;
		color: #000000;
		&:after{
			border-right-color: #ffffff;
			border-top-color: #ffffff;
		}		
	}
	
	&.conversation-list .odd .ctext-wrap{		
		background: #dcf8c6;
		color: #000000;
		&:after{
			border-top-color: #dcf8c6;
			border-left-color: #dcf8c6;
			border-right-color: transparent;
		}
	}
	
	&.conversation-list .chat-avatar{
		width: 40px;
	}
}

.chat-send{
	background-color: #006239;
	border-color: #006239;
}

.whats-like-chat{
	background: #e5ddd5;
    padding: 15px 10px;
    border-radius: 5px;
}

.chat-use-item{
	padding:7px 10px;
	cursor:pointer;
	&.chat-active{
		background:#f3f4f7;
		border-radius:7px;
	}
}

.chat-message-wrapper{
	background:#f0f0f0;
	padding-top:7px;
	padding-bottom:7px;
	border-radius:6px;
	.form-group{
		margin-bottom:0px;
	}
	.form-control{
		border-radius:500px;
	}
}

@media only screen and (min-width: 1025px) and (max-width: 1399px){
	.profile-image-border{	
		.upload-progress-bar-wrapper{
			width: 244px;
		}
	}
	.ic-position {
		bottom: 50px;
		right: 60px;
	}
}

@media only screen and (max-width: 1024px){
	.profile-image-border{	
		.upload-progress-bar-wrapper{
			width: 186px;
		}
	}
	.ic-position {
		bottom: 46px;
		right: 37px;
	}
	.poll-question-right{
		width:100%;
	}
	
	.top-nav-mda-name{
		margin-left:40px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1499px){
	.dashboard-admin-col{
		.col-lg-12 {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}

@media only screen and (min-width: 300px) and (max-width: 400px)
     {
		.mob-2
		{
          margin-bottom:5%;
		}
     }
@media only screen and (min-width: 300px) and (max-width: 800px)
{
.mobile-form
     {
		 margin-top:auto;
		 margin-bottom:auto;
     }
	 .dashboard-col .col-xl-2
	 {
	 max-width:100%!important;
	 margin-top:10px;
	 }
	 .dashboard-col .col-xl-2
	 {
	 flex:none!important;
	 }

}

@media only screen and (min-width: 600px) and (min-height: 800px)
{
.mobile-form
     {
		 margin-top:41%;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 1024px) and (min-height: 768px)
{
.mobile-form
     {
		 margin-top:9%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 768px) and (min-height: 1024px)
{
.mobile-form
     {
		 margin-top:29%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 411px) and (min-height: 731px)
{
.mobile-form
     {
		 margin-top:14%;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 768px) and (min-height: 1024px)
{
.mobile-form
     {
		 margin-top:35%;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 375px) and (min-height: 812px)
{
.mobile-form
     {
		 margin-top:30%;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 384px) and (min-height: 640px)
{
.mobile-form
     {
		 margin-top:10%;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 1024px) and (min-height: 1366px)
{
.mobile-form
     {
		 margin-top:30%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 1366px) and (min-height: 1024px)
{
.mobile-form
     {
		 margin-top:40%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 700px) and (min-height: 1280px)
{
.mobile-form
     {
		 margin-top:60%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 800px) and (min-height: 1280px)
{
.mobile-form
     {
		 margin-top:46%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 1280px) and (min-height: 800px)
{
.mob-forget
     {
		 margin-top:25%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 1280px) and (min-height: 800px)
{
.mobile-form
     {
		 margin-top:20%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 1440px) and (min-height: 900px)
{
.mobile-form
     {
		 margin-top:25%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 600px) and (min-height: 1024px)
{
.mobile-form
     {
		 margin-top:40%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 1280px) and (min-height: 950px)
{
.mobile-form
     {
		 margin-top:30%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 1280px) and (min-height: 950px)
{
.mob-forget
     {
		 margin-top:35%!important;
		 margin-bottom:auto;
     }
}
@media only screen and (min-width: 300px) and (max-width: 1030px){
.navbar-custom .logo
 {
    display: block !important; 
}
.navbar-custom .button-menu-mobile
{
margin-right:27px!important;
}
}


@media only screen and (min-width: 600px) and (max-width: 612px){
	.mobile-form {
		margin-top: 20%!important;
		margin-bottom: auto;
	}
}
@media only screen and (min-height: 992px) and (max-height: 1024px){
	.mobile-form {
		margin-top: 23%!important;
		margin-bottom: auto;
	}
}

@media (min-width: 768px){
   .green-width{
		flex: 0 0 29.2%;
		max-width: 29.2%;
	}
	.light-green-width{
		flex: 0 0 23.6%;
		max-width: 23.6%;
	}  
}

@media (max-width: 480px){
   .chart-polling-filter-wrapper{
		flex-wrap:wrap;
		.chart-polling-filter-options{
			.react-select{
				min-width:300px;
			}
			margin-bottom:10px;
		}
	}
	
	.chart-filter-options{
		&.absolute-filter{
			position: relative;
			right: 0px;
			z-index: 1000;
		}
	}
	
	.top-nav-mda-name{
		margin-left: 15px;
		font-size: 12px;
	}
	
	.left-side-menu-condensed .logo{
		width:auto !important;
	}
}